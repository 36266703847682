<template>
  <div class="page-coupon-details">
    page {{ title }} is working!
  </div>
</template>

<script lang="js">
import CouponDetailsPage from './coupon-details';

export default CouponDetailsPage;
</script>

<style lang="scss">
@import "./coupon-details";
</style>
